import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import entities from "./entities";

let reducers = {};
try {
  reducers = Object.entries(entities).reduce((acc, [key, value]) => {
    acc[key] = value[key].reducer;
    return acc;
  }, {});
} catch (err) {
  console.error(err);
  window.location.reload();
}

const rootReducer: any = combineReducers(reducers);

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENV === "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
