import { useCandidates } from "../../redux/scrutins/hooks";

import { ballot } from "../Verification/Verification";

// Components
import Icon from "../../atoms/Icon/Icon";
import useLocalize from "../../locales/hook";
import CandidateItem from "../../components/CandidateItemCheck/CandidateItemCheck";

// Style & Types

import IconTypes from "../../utils/types/IconTypes";

const WHITE_VOTE = "vote_blanc";
interface VisualizeBallotProps {
  ballot: ballot;
}

const VisualizeBallot: React.FC<VisualizeBallotProps> = ({ ballot }) => {
  const t = useLocalize();

  const ballotData = {
    listId:
      ballot?.listId == "vote_blanc" ? ballot.listCandidateId : ballot?.listId,
    domain: ballot.domain,
    apiKeyThirdParty: ballot.apiKeyThirdParty,
    crossedOutCandidateIds: ballot.crossedOutCandidateIds,
    scrutinId: ballot.scrutinId,
    listCandidateId: ballot.listCandidateId,
  };

  const { userIds, candidateIds, poll, listInfo } = useCandidates(ballotData);
  const handleChange = () => {};

  const handleClickPDF = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="box">
      {poll?.mandate && (
        <div className="pageTitle">
          {t("scrutin_lists")} - {poll?.college?.establishment?.name} -{" "}
          {t("college_" + poll?.college?.type)}
          {" - "}
          {t("polling_" + poll?.mandate)}
        </div>
      )}

      {ballot?.listId === WHITE_VOTE && (
        <div className="head">
          <div className="white" />
          <div className="info">
            <div className="label">{t("white_vote")}</div>
          </div>
          <Icon className="check" icon={IconTypes.CHECK_CIRCLE} />
        </div>
      )}

      {listInfo?.title && ballot?.listId !== WHITE_VOTE && (
        <>
          <div className="head">
            {!!listInfo?.logoUrl?.length && (
              <img alt={listInfo?.title} src={listInfo?.logoUrl} />
            )}

            <div className="info">
              <div className="label">{listInfo?.title}</div>

              {!!listInfo?.professionOfFaithUrl?.length && (
                <Icon
                  icon={IconTypes.BOOK_OPEN}
                  onClick={() => handleClickPDF(listInfo?.professionOfFaithUrl)}
                />
              )}
            </div>
          </div>

          {userIds.map((id, index) => (
            <CandidateItem
              userId={id}
              ballotData={ballotData}
              candidateId={candidateIds[index]}
              key={id}
              order={index + 1}
              selected={
                !ballot?.crossedOutCandidateIds.includes(candidateIds[index])
              }
              isVoteAvailable={false}
              isValidationScreen={false}
              isVerification={
                !ballot?.crossedOutCandidateIds.includes(candidateIds[index])
              }
              onClick={handleChange}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default VisualizeBallot;
