import React from 'react'
import Icon from '../Icon/Icon'

import './Tooltip.scss'
import IconTypes from '../../utils/types/IconTypes'

interface TooltipType {
  label: string
  children?: any
  disabled?: boolean
  vertical?: 'top' | 'bottom'
  horizontal?: 'left' | 'right' | 'center'
}

const Tooltip: React.FC<TooltipType> = ({
  label,
  disabled,
  children,
  horizontal = 'right',
  vertical = 'top',
}) => (
  <div id='Tooltip'>
    {!children && <Icon className='toolTipIcon' icon={IconTypes.INFO} />}
    {!!children && children}
    {!disabled && <div className={`tooltipInfo ${horizontal} ${vertical}`}>{label}</div>}
  </div>
)

export default React.memo(Tooltip)
