import { createAsyncThunk } from "@reduxjs/toolkit";
import { CIVILITY_DISPLAY, SEX_DISPLAY } from "../../locales/constants";
import { getAxios, setAxios } from "../../utils/lib/requestAxios";
import { renderUrlApi } from "../../utils/lib/utils";

const addUser = (state, user, userId) => {
  if (user?.user) {
    user = user?.user;
  }
  if (user?.id === state.userId && !!state.userById[userId])
    return state.userById[userId];
  return {
    userId,
    username: user?.login,
    pic: user?.avatar,
    ...user,
    college: { type: `college_${user?.college?.type}` },
    sex: SEX_DISPLAY[user?.sex],
    civility: CIVILITY_DISPLAY[user?.civility],
  };
};

// Request 1 User
export const requestUser = createAsyncThunk<any, any>(
  "users/requestUser",
  async ({ userId, domain, apiKeyThirdParty }, { rejectWithValue }) => {
    const url = renderUrlApi(domain);
    const response: any = await setAxios(url + `/thirdParty/user`, {
      id: userId,
      apiKeyThirdParty: apiKeyThirdParty,
    });

    if (response.error) {
      return rejectWithValue(response.error.response.status);
    } else {
      return response.data;
    }
  }
);

export const requestUserStates = (builder) => {
  builder.addCase(requestUser.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(requestUser.fulfilled, (state, { payload: user }) => {
    const { id: userId } = user;
    state.userById[userId] = addUser(state, user, userId);
  });

  builder.addCase(requestUser.rejected, (state) => {
    state.isLoading = false;
  });
};
