import { useCallback } from "react";
import { requestCheckBallot } from "./services";
import { useAppDispatch, useAppSelector } from "../../init/store";
import { ResetReceit } from "./redux";

export const useControlBallot = () => {
  const dispatch = useAppDispatch();

  const receipt = useAppSelector((state) => state.receipt?.receipt);
  const ballot = useAppSelector((state) => state.scrutins?.ballot);
  const isLoading = useAppSelector((state) => state.receipt?.isLoading);

  const resetReceipt = useCallback(() => dispatch(ResetReceit()), [dispatch]);
  const checkBallot = useCallback(
    (data) => {
      dispatch(
        requestCheckBallot({
          pollId: ballot?.pollId,
          print: data.print,
          domain: data?.domain,
          apiKeyThirdParty: data?.apiKeyThirdParty,
        })
      );
    },
    [ballot?.pollId, dispatch]
  );

  return {
    receipt,
    isLoading,
    checkBallot,
    resetReceipt,
  };
};
