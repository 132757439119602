import { createSlice } from "@reduxjs/toolkit";

// Services
import { requestCandidatesState } from "./services";

// Utils

// Define a type for the slice state
export type ScrutinsState = {
  scrutinsElections: Array<any>;
  scrutins: Array<any>;
  elections: Array<any>;
  scrutinsById: { [id: string]: any };
  listsById: { [id: string]: any };
  isLoading: boolean;
  collegesById: { [id: string]: any };
  errorsById: { [id: string]: any };
  shouldReload: boolean;
  encryptionKey: string;
  encryptedBallot: string;
  ballot: any;
  mandatoryVerifications: any;
  integrity: any;
};

// Define the initial state using that type
const initialState: ScrutinsState = {
  elections: [],
  scrutinsElections: [],
  integrity: {},
  scrutins: [],
  scrutinsById: {},
  isLoading: false,
  listsById: {},
  collegesById: {},
  shouldReload: false,
  encryptionKey: "",
  encryptedBallot: "",
  ballot: {},
  mandatoryVerifications: {},
  errorsById: {},
};

export const scrutins = createSlice({
  name: "scrutins",
  initialState,
  reducers: {
    ResetIntegrity: (state) => {
      state.integrity = {};
    },
  },
  extraReducers: (builder) => {
    requestCandidatesState(builder);
  },
});
