// Hooks
import { useCallback } from "react";
import { useUser } from "../../redux/user/hooks";
//import { useDisplayCandidatePicture } from '../../redux/configuration/hooks'

// Components
import Icon from "../../atoms/Icon/Icon";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import UserDefaultPicture from "../UserDefaultPicture/UserDefaultPicture";

// Style & Utils & Types
import "./CandidateItemCheck.scss";
import IconTypes from "../../utils/types/IconTypes";
import getFullName from "../../utils/lib/getFullName";
import { useCandidate } from "../../redux/candidates/hooks";
import Tooltip from "../../atoms/Tooltip/Tooltip";
import useLocalize from "../../locales/hook";

type Props = {
  userId: string;
  candidateId: number;
  ballotData: ballotDto;
  order?: number;
  isValidationScreen?: boolean;
  isVerification?: boolean;
  selected: boolean;
  isVoteAvailable: boolean;
  onClick: (id: number) => void;
};

type ballotDto = {
  domain: string;
  apiKeyThirdParty: string;
};

const CandidateItemCheck: React.FC<Props> = ({
  userId,
  candidateId,
  ballotData,
  order,
  isVerification = false,
  selected,
  isValidationScreen,
  isVoteAvailable,
  onClick,
}) => {
  const t = useLocalize();
  const { user } = useUser(
    userId,
    ballotData.domain,
    ballotData.apiKeyThirdParty
  );
  const { candidate } = useCandidate(candidateId);

  // Handlers
  const handleClick = useCallback(
    () => onClick(candidateId),
    [onClick, candidateId]
  );

  const handleVideo = useCallback(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      window.open(candidate.videoUrl, "_blank");
    },
    [candidate]
  );
  const handlePDF = useCallback(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      window.open(candidate.presentationUrl, "_blank");
    },
    [candidate]
  );

  //if (!user?.id) return null
  return (
    <div
      id="CandidateItemCheck"
      className={`candidateItemCheck ${selected ? "" : "notChosen"} ${
        isValidationScreen || !isVoteAvailable ? "noVote" : ""
      }`}
      onClick={!isValidationScreen && isVoteAvailable ? handleClick : undefined}
    >
      {!!order && <div className="order">{order}</div>}
      <UserDefaultPicture
        className="candidatePhoto"
        userId={userId}
        user={user}
        src={candidate?.profilePictureUrl}
        candidatePicturesDisplayed={false}
      />
      <div className="candidateInfo">
        <div className="name">{getFullName(user)}</div>
        {!!candidate?.description && (
          <div className="description">{candidate.description}</div>
        )}
      </div>
      <div className="rightSide">
        {!!candidate.presentationUrl && (
          <Tooltip label={t("consult_profession_pdf")} horizontal="left">
            <Icon
              className="iconType"
              icon={IconTypes.FILE_TEXT}
              onClick={handlePDF}
            />
          </Tooltip>
        )}
        {!!candidate.videoUrl && (
          <Tooltip label={t("consult_presentation_video")} horizontal="left">
            <Icon
              className="iconType"
              icon={IconTypes.PLAY}
              onClick={handleVideo}
            />
          </Tooltip>
        )}
        {isVoteAvailable && (
          <>
            {!isValidationScreen && <CheckBox addIcon selected={selected} />}
            {isValidationScreen && selected && (
              <Icon className="check" icon={IconTypes.CHECK_CIRCLE} />
            )}
          </>
        )}

        {isVerification && (
          <>
            <Icon className="check" icon={IconTypes.CHECK_CIRCLE} />
          </>
        )}
      </div>
    </div>
  );
};

export default CandidateItemCheck;
