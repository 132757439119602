import { store } from "./init/store";
import { Provider } from "react-redux";
import { setupIonicReact } from "@ionic/react";
import "./App.css";
import Verification from "./components/Verification/Verification";

import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.scss";
import "./theme/global.scss";

setupIonicReact();

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Verification />
      </div>
    </Provider>
  );
}

export default App;
