// Hooks
import React, { useMemo } from "react";
// Components
import Icon from "../../atoms/Icon/Icon";

// Utils
import getFullName from "../../utils/lib/getFullName";
import getColorMaker from "../../utils/lib/getColorMarker";
import getFirstLetters from "../../utils/lib/getFirstLetters";

// Style & Types
import "./UserDefaultPicture.scss";
import IconTypes from "../../utils/types/IconTypes";

interface UserPicture {
  userId?: string | null;
  className?: string;
  name?: string;
  elementId?: string;
  user: user;
  src?: string;
  candidatePicturesDisplayed?: boolean;
}

interface user {
  firstName: string;
  lastName: string;
  avatar: string;
  id: string;
}

const UserDefaultPicture: React.FC<UserPicture> = ({
  userId,
  src,
  className = "",
  name = "",
  user: user,
  elementId = "UserPicture",
  candidatePicturesDisplayed = true,
}) => {
  // Memos
  const fullName = useMemo(() => getFullName(user) || name, [user, name]);
  const backgroundStyle = useMemo(
    () => ({ backgroundColor: getColorMaker(fullName, user?.firstName) }),
    [fullName, user]
  );
  const displayName = useMemo(
    () => getFirstLetters(user) || name,
    [user, name]
  );

  if (!user && !name) {
    return (
      <div
        id={elementId}
        className={`UserDefaultProfilePicture icon hasPic ${className}`}
      >
        <Icon icon={IconTypes.USER} />
      </div>
    );
  }

  if ((!!user?.avatar || !!src) && !!candidatePicturesDisplayed) {
    return (
      <div
        id={elementId}
        className={`UserDefaultProfilePicture hasPic ${className}`}
      >
        <img alt={fullName || ""} src={src || user?.avatar} />
      </div>
    );
  }

  return (
    <div
      id={elementId}
      className={`UserDefaultProfilePicture ${className} ${
        !user ? "no_user" : ""
      }`}
      style={backgroundStyle}
    >
      <div className="InitialsPicture" data-testid="initials">
        {displayName}
      </div>
    </div>
  );
};

export default React.memo(UserDefaultPicture);
