export const CIVILITY = ["Monsieur", "Madame", "Mademoiselle"];

export const CIVILITY_DISPLAY = {
  Monsieur: "Monsieur",
  MISTER: "Monsieur",
  Madame: "Madame",
  Mademoiselle: "Mademoiselle",
  MISS: "Mademoiselle",
  MADAM: "Madame",
};

export const SEX = ["H", "F"];

export const SEX_DISPLAY = {
  H: "H",
  F: "F",
  MAN: "H",
  WOMAN: "F",
};
