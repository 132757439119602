import CryptoJS from "crypto-js";

const SECRET = "Random value to be set";

export const encryptData = (value, secret = SECRET) =>
  CryptoJS.AES.encrypt(
    typeof value === "object" ? JSON.stringify(value) : value,
    secret
  ).toString();

export const decryptData = (token) => {
  const bytes = CryptoJS.AES.decrypt(token, SECRET);
  const data = bytes.toString(CryptoJS.enc.Utf8);

  try {
    // In case the value is an object
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

export const decryptObject = (encryptedPayload, keyHex, ivHex) => {
  const key = CryptoJS.enc.Hex.parse(keyHex);
  const iv = CryptoJS.enc.Hex.parse(ivHex);

  // Déchiffrement
  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(encryptedPayload), // Parse en base64
    },
    key,
    {
      iv: iv,
      mode: CryptoJS.mode.CBC, // Mode CBC
      padding: CryptoJS.pad.Pkcs7, // Padding Pkcs7
    }
  );

  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

  // Vérification du déchiffrement réussi
  if (!decryptedText) {
    throw new Error("Le déchiffrement a échoué");
  }

  // Conversion de la chaîne JSON en objet JavaScript
  const decryptedObject = JSON.parse(decryptedText);

  return decryptedObject;
};
