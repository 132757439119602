import { IonContent, IonPage } from "@ionic/react";

// Hooks
import { useCallback, useState, useMemo, useEffect } from "react";
import useLocalize from "../../locales/hook";
import { useControlBallot } from "../../redux/receipt/hooks";

import { useStatusIntegrity } from "../../redux/scrutins/hooks";

import IconTypes from "../../utils/types/IconTypes";
import TabItem from "../../components/TabItem/TabItem";
import Button from "../../atoms/Button/Button";
import Image from "../../atoms/Image/Image";
import TextInput from "../../atoms/TextInput/TextInput";

// Types & Styles
import "./Verification.scss";
import VisualizeBallot from "../../components/VisualizeBallot/VisualizeBallot";

export interface ballot {
  listId: string;
  crossedOutCandidateIds: number[]; // Ensure this matches your requirements
  scrutinId: string;
  apiKeyThirdParty: string;
  listCandidateId: string;
  domain: string;
}

export interface ballotPrint {
  print: string;
  apiKeyThirdParty: string;
  domain: string;
}

const Verification: React.FC<unknown> = () => {
  const { checkBallot, receipt, isLoading } = useControlBallot();
  const integrity = useStatusIntegrity();

  const t = useLocalize();

  const TABS_TYPES = {
    PRINT: "PRINT",
    INTEGRITY: "INTEGRITY",
    LOADING: "LOADING",
  };

  const TABS = [
    { id: TABS_TYPES.PRINT, label: "check_print" },
    { id: TABS_TYPES.INTEGRITY, label: "check_integrity" },
  ];

  const initialBallot: ballot = {
    listId: "",
    crossedOutCandidateIds: [],
    scrutinId: "",
    apiKeyThirdParty: "",
    listCandidateId: "",
    domain: "",
  };

  const [ballotPrint, setBallotPrint] = useState(
    '{ print: "", apiKeyThirdParty: "",domain: ""}'
  );

  const [ballot, setBallot] = useState<ballot>(initialBallot); // État pour l'objet ballot
  const [jsonString, setJsonString] = useState(
    '{ listId: "", crossedOutCandidateIds: [], voteSector: "",domain: "",apiKeyThirdParty: ""}'
  );
  const [isError, setIsError] = useState({ print: false, integrity: false });

  const [lastTab, setLastTab] = useState(TABS_TYPES.PRINT);
  const [selectedTab, setSelectedTab] = useState(TABS_TYPES.LOADING);

  useEffect(() => {
    let url = window.location.href;
    let TABS_LINK = url.substring(url.lastIndexOf("/") + 1);
    if (!TABS_LINK) {
      setSelectedTab(TABS_TYPES.PRINT);
      return;
    }

    setSelectedTab(TABS_LINK);
  }, []);

  const handleClickTab = useCallback(
    (tab) => {
      setLastTab(selectedTab);
      setSelectedTab(tab);
    },
    [selectedTab]
  );

  const handleClickCheck = useCallback(
    (e) => {
      e.stopPropagation();
      handleError(TABS_TYPES.LOADING);
      try {
        const parsedObject = JSON.parse(ballotPrint);

        checkBallot(parsedObject);
      } catch (e) {
        handleError(TABS_TYPES.PRINT);
        console.log(e);
      }
    },
    [ballotPrint, checkBallot, initialBallot]
  );

  const handleError = (data) => {
    if (data == TABS_TYPES.INTEGRITY) {
      setIsError({ print: false, integrity: true });
      setBallot(initialBallot);
    } else if (data == TABS_TYPES.PRINT) {
      setIsError({ print: true, integrity: false });
    } else {
      setIsError({ print: false, integrity: false });
    }
  };
  // Convertissez la chaîne JSON en objet ballot
  const parseJson = useCallback(() => {
    try {
      handleError(TABS_TYPES.LOADING);
      const parsedObject = JSON.parse(jsonString);
      if (hasAllKeys(parsedObject, initialBallot)) {
        setBallot(parsedObject);
      } else {
        handleError(TABS_TYPES.INTEGRITY);
      }
    } catch (error) {
      handleError(TABS_TYPES.INTEGRITY);
      console.log("Invalid JSON format:");
    }
  }, [jsonString, initialBallot]);

  const getStatus = useCallback(
    (type) => {
      if (type === TABS_TYPES.PRINT && isError.print === true) return "invalid";
      if (type === TABS_TYPES.INTEGRITY && isError.integrity === true)
        return "invalid";

      if (isLoading) return "";

      if (type === TABS_TYPES.PRINT && receipt?.isError !== undefined) {
        return receipt.isError ? "invalid" : "valid";
      }

      if (type === TABS_TYPES.INTEGRITY && integrity?.isError !== undefined) {
        return integrity.isError ? "invalid" : "valid";
      }

      return "";
    },
    [isError, isLoading, receipt, integrity]
  );

  const getIcon = useCallback(
    (status) => {
      if (isLoading) return IconTypes.LOADER;
      if (status === "valid") return IconTypes.CHECK_CIRCLE;
      if (status === "invalid") return IconTypes.X_CIRCLE;
      return undefined;
    },
    [isLoading]
  );

  const statusPrint = useMemo(() => getStatus(TABS_TYPES.PRINT), [getStatus]);
  const statusBuild = useMemo(
    () => getStatus(TABS_TYPES.INTEGRITY),
    [getStatus]
  );
  const iconPrint = useMemo(() => getIcon(statusPrint), [getIcon, statusPrint]);
  const iconBuild = useMemo(() => getIcon(statusBuild), [getIcon, statusBuild]);

  return (
    <IonPage>
      <IonContent id="Verification" scrollEvents>
        <div className="container">
          <div className="pageTitle">{t("welcome_message")}</div>

          <div className="tabsContainer">
            {TABS.map((tab) => (
              <TabItem
                selected={
                  (selectedTab === TABS_TYPES.LOADING
                    ? lastTab
                    : selectedTab) === tab.id
                }
                key={tab.id}
                title={t(tab.label)}
                onClick={handleClickTab}
                value={tab.id}
              />
            ))}
          </div>
          <div className="boxContain">
            {selectedTab === TABS_TYPES.PRINT && (
              <div className="box">
                <div className="pageTitle">
                  {t("confirm_ballot_presence_title")}
                </div>

                <div className="titleControl">
                  {t("level3_control_activated")}
                </div>

                <div className="ballotControlContent">
                  <Image src="confirm-logout" />

                  <div className={"fieldContainer " + statusPrint}>
                    <TextInput
                      label={t("ballot_print")}
                      autocomplete="off"
                      valueKey="username"
                      onChange={setBallotPrint}
                      icon={iconPrint}
                      maxLength={300}
                    />
                    <div className="buttonContainer">
                      <Button
                        label={t("verify")}
                        fill
                        onClick={handleClickCheck}
                      />
                    </div>
                  </div>
                  <div className={"infoControl "}>
                    {t(statusPrint && `control_ballot_${statusPrint}`)}
                  </div>
                </div>
              </div>
            )}

            {selectedTab === TABS_TYPES.INTEGRITY && (
              <div className="box">
                <div className="pageTitle">{t("check_integrity")}</div>

                <VisualizeBallot ballot={ballot} />

                <div className="ballotControlContent">
                  <div className={"fieldContainer " + statusBuild}>
                    <TextInput
                      label={t("json_ballot")}
                      autocomplete="off"
                      valueKey="ballot"
                      onChange={setJsonString} // Corrected onChange handler
                      icon={iconBuild}
                      maxLength={800}
                    />

                    <div className="buttonContainer">
                      <Button label={t("verify")} fill onClick={parseJson} />
                    </div>
                  </div>

                  <div className={"infoControl "}>
                    {t(statusBuild && `control_integrity_${statusBuild}`)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

// Function to check if an object has all keys from the reference object
const hasAllKeys = (obj: any, reference: any): boolean => {
  return Object.keys(reference).every((key) => key in obj);
};

export default Verification;
