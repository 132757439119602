import { createSlice } from "@reduxjs/toolkit";
//  Services
import { requestListsStates } from "./services";

// Define a type for the slice state
export type CandidatesState = {
  candidates: Array<any>;
  candidatesById: { [id: string]: any };
  isLoading: boolean;
};

// Define the initial state using that type
const initialState: CandidatesState = {
  candidates: [],
  candidatesById: {},
  isLoading: false,
};

export const candidates = createSlice({
  name: "candidates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestListsStates(builder);
  },
});
