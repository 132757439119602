import { useCallback, useMemo } from "react";

import "./TabItem.scss";

const TabItem: React.FC<{
  title: string;
  value?: any;
  isDisabled?: boolean;
  selected?: boolean;
  onClick?: (val: any) => void;
}> = ({ value, selected, title, onClick, isDisabled }) => {
  const className = useMemo(
    () =>
      `componentTabItem ${selected} ${!!onClick ? "pointer" : ""} ${
        isDisabled ? "disabled" : ""
      }`,
    [isDisabled, onClick, selected]
  );
  const handleClick = useCallback(() => onClick?.(value), [onClick, value]);

  return (
    <div
      key={value}
      className={className}
      onClick={isDisabled ? undefined : handleClick}
    >
      <div className="text">{title}</div>
      <div className="bar" />
    </div>
  );
};

export default TabItem;
