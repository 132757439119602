// This function is to trim the JSON
export const ALPHABETIC_REGEX = "^[A-Za-zÀ-ÿ]+$";
export const ALPHANUMERIC_REGEX = "^[A-Za-zÀ-ÿ0-9]+$";
export const LUHN_FORMULA_REGEX = "^[0-9]+$";
export const NO_AMBIGUOUS = "^[^iI1loO0]+$";
export const ALPHA_REGEX = "^[A-Za-zÀ-ÿ? ?-]+$";

export const NUMERIC_REGEX = "^[0-9]+$";

export const FLOAD_NUMERIC_REGEX = "^[0-9?,]+$";

export const renderUrlApi = (domain) => {
  return domain.replace(/[/\\]$/g, "");
};
