import * as candidates from "../redux/candidates/redux";
import * as receipt from "../redux/receipt/redux";
import * as scrutins from "../redux/scrutins/redux";
import * as users from "../redux/user/redux";

const entities = {
  candidates,
  scrutins,
  receipt,
  users,
};

export default entities;
