import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAxios } from "../../utils/lib/requestAxios";

import { renderUrlApi } from "../../utils/lib/utils";

export const requestCheckBallot = createAsyncThunk<
  unknown,
  { pollId: string; print?: string; domain?: string; apiKeyThirdParty?: string }
>(
  "receipt/requestCheckBallot",
  async ({ print, domain, apiKeyThirdParty }, { rejectWithValue }) => {
    const url = renderUrlApi(domain);

    const response: any = await setAxios(url + `/thirdParty/check`, {
      print: print,
      apiKeyThirdParty: apiKeyThirdParty,
    });

    if (response.error) {
      return rejectWithValue(response.error.response.status);
    } else {
      return response.data;
    }
  }
);

export const requestCheckBallotStates = (builder) => {
  builder.addCase(requestCheckBallot.pending, (state) => {
    state.isLoading = true;
    state.receipt = {};
  });

  builder.addCase(requestCheckBallot.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.receipt = { ...payload, isError: false };
  });

  builder.addCase(requestCheckBallot.rejected, (state) => {
    state.isLoading = false;
    state.receipt = { isError: true };
  });
};
