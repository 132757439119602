import { requestCandidates } from "../scrutins/services";

export const requestListsStates = (builder) => {
  builder.addCase(requestCandidates.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(requestCandidates.fulfilled, (state, { payload }) => {
    state.isLoading = false;

    if (payload.data) {
      state.candidatesById = {
        ...state.candidatesById,
        ...payload.data?.candidates?.reduce((acc, candidate) => {
          acc[candidate.id] = candidate;
          return acc;
        }, {}),
      };
    }
  });

  builder.addCase(requestCandidates.rejected, (state) => {
    state.isLoading = false;
  });
};
