import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../init/store";
import { requestUser } from "./services";

export const useUser = (
  userId: string | null,
  domain: string | null,
  apiKeyThirdParty: string | null
) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users?.userById[userId || 0]);

  const getUser = useCallback(
    () => dispatch(requestUser({ userId, domain, apiKeyThirdParty })),
    [userId, dispatch]
  );

  useEffect(() => {
    if (userId && !user) {
      getUser();
    }
  }, [getUser, user, userId]);

  return { user, getUser };
};
