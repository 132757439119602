import { createSlice } from "@reduxjs/toolkit";
import { requestCheckBallotStates } from "./services";

// Define a type for the slice state
export type receiptState = {
  receipt: any;
  isLoading: boolean;
};

// Define the initial state using that type
const initialState: receiptState = {
  receipt: {},
  isLoading: false,
};

export const receipt = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    ResetReceit: (state) => {
      state.receipt = {};
    },
  },
  extraReducers: (builder) => {
    requestCheckBallotStates(builder);
  },
});

export const { ResetReceit } = receipt.actions;
