import { useCallback, useEffect, useMemo } from "react";
import { requestCandidates } from "./services";
import { useAppDispatch, useAppSelector } from "../../init/store";
import { ballot } from "../../components/Verification/Verification";

// Candidates
export const useCandidates = (ballot: ballot) => {
  const dispatch = useAppDispatch();

  const getCandidates = useCallback(
    () => dispatch(requestCandidates(ballot)),

    [parseInt(ballot?.listId), dispatch]
  );

  useEffect(() => {
    !isNaN(Number(parseInt(ballot?.listId))) && getCandidates();
  }, [getCandidates, parseInt(ballot?.listId)]);

  const list = useAppSelector(
    (state) => state.scrutins.listsById[parseInt(ballot?.listId)]
  );

  const integrity = useAppSelector((state) => state.scrutins?.integrity);

  const userIds = useMemo(() => list?.candidates, [list]);

  const candidateIds = useMemo(() => list?.candidateIds, [list]);

  const poll = useMemo(() => list?.poll, [list]);
  const college = useMemo(() => list?.college, [list]);
  const listInfo = useMemo(() => list?.listInfo, [list]);

  return {
    userIds: userIds || [],
    candidateIds: candidateIds || [],
    poll: poll || [],
    college: college || [],
    listInfo: listInfo || [],
    integrity: integrity || {},
  };
};

export const useStatusIntegrity = () => {
  const integrity = useAppSelector((state) => state.scrutins?.integrity);
  return integrity;
};
